import * as React from "react"
import "@fontsource/raleway/400.css"
import styled from 'styled-components'
import quote from '../images/common/open-quote.png'

// styles

const BlockQuoteWrapper = styled.blockquote`
  margin: 0 auto 30px;
  font-style: italic;
  max-width: 700px;
  padding: 10px 20px 0 50px;
  position: relative;
  background: url(${quote}) 0 0 no-repeat;

  p {
    margin-bottom: 0;
    line-height: 22px;
    font-family: Garamond;
  }
  footer {
    text-align: right;
    display: block;
    clear: both;
    font-size: 12px;
    margin-top: 2px;

    &:before {
      content: "- "
    }
  }
`;

// markup
const BlockQuote = (props) => {
  return (
    <BlockQuoteWrapper>
      <p dangerouslySetInnerHTML={{__html: props.quote.content}}/>
      <footer>{props.quote.quotee}</footer>
    </BlockQuoteWrapper>
  )
}

export default BlockQuote